<template>
  <div data-app>
    <v-card>
      <v-card-title>
        Web Categories
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-row>
        <v-col cols="12" sm="12" md="12">
          <WebCategoryModalForm
            :modalData="modalData"
            :editedItem="editedItem"
            :permissions="permissions"
            :categories="webCategoryCollection"
            :itemCollection="itemCollection"
            :quantityUnitCollection="quantityUnitCollection"
            @closeModalForm="handleCloseModalForm"
            @saveModalForm="handleSaveModalForm"
          ></WebCategoryModalForm>

          <AlertComponent
            :alertShow="alert.show"
            :alertText="alert.text"
            @closeAlert="alert.show = false"
          ></AlertComponent>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          class="ml-9 pb-0"
          style="rgba(0, 0, 0, 0.6);font-size: 0.75rem;"
        >
          <b>Web Category name</b>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="pt-0">
          <!-- <pre>{{ categoryTree }}</pre> -->
          <!-- @update:active="handleEditCategory" -->
          <!-- <v-treeview
            shaped
            hoverable
            activatable
            :search="search"
            :items="categoryTree"
            :headers="headers"
          >
            <template slot="label" slot-scope="{ item }">
              <v-row @click="handleEditCategory([item.id])">
                <v-col cols="7">
                  {{ getTranslated(item) }}
                </v-col>
                <v-col cols="4">
                  No. of Items: {{ item.items_num }}, No. of Web Categories:
                  {{ item.categories_num }}
                </v-col>
                <v-col cols="1">
                  <v-icon
                    v-if="permissionCan('delete') && item.categories_num == 0"
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </v-col>
              </v-row>
            </template>
          </v-treeview> -->
          <!-- <pre>
            {{ categoryTree }}
          </pre> -->

          <vuetify-draggable-treeview
            v-model="categoryTree"
            v-on:input="moveMenuItem"
          >
            <template slot="label" slot-scope="{ item }">
              <v-row @click="handleEditCategory([item.id])">
                <v-col cols="7">
                  {{ item.name }}
                </v-col>
                <v-col cols="4">
                  No. of Items: {{ item.items_num }}, No. of Web Categories:
                  {{ item.categories_num }}
                </v-col>
                <v-col cols="1">
                  <v-icon
                    v-if="permissionCan('delete') && item.categories_num == 0"
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </v-col>
              </v-row>
            </template>

            <!-- <template v-slot:append="{ item }">

                <v-icon
                  small
                  color="primary"
                  v-if="permissionCan('delete')"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template> -->
          </vuetify-draggable-treeview>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <file-manager></file-manager>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import VuetifyDraggableTreeview from "vuetify-draggable-treeview";

import ApiService from "@/core/services/api.service";
import AlertComponent from "@/view/components/AlertComponent";
import WebCategoryModalForm, { initialFormData } from "./WebCategoryModalForm";
import i18nService from "@/core/services/i18n.service.js";
// import FileManager from 'laravel-file-manager'

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export const ENDPOINT = "inventory/webCategory/";

export default {
  name: "categories",
  components: {
    WebCategoryModalForm,
    AlertComponent,
    VuetifyDraggableTreeview,
  },
  data() {
    return {
      loader: false,

      showAll: false,
      search: "",
      collection: [],
      categoryTree: [],
      permissions: [],
      appLocale: i18nService.getActiveLanguage(),
      alert: {
        show: false,
        text: "You can't delete a web category that contains either items or web categories",
      },
      headers: [
        {
          text: "Web Category name",
          value: "name",
        },
        {
          text: "Items",
          value: "items_num",
        },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        loading: false,
        // editedItem: Object.assign({}, initialFormData),
      },
      editedItem: Object.assign({}, initialFormData),

      dialogDelete: false,
      routePath: "/inventory/webCategories/",
    };
  },

  computed: {
    ...mapGetters([
      "webCategoryCollection",
      "itemCollection",
      "quantityUnitCollection",
    ]),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
    webCategoryCollection(val) {
      this.filterCollection(val);
    },
  },

  methods: {
    ...mapActions(["fetchWebCategory", "fetchItem", "fetchQuantityUnit"]),
    editItem(id) {
      this.loader = true;
      ApiService.get(ENDPOINT + id)
        .then(({ data }) => {
          data.itemObjects = data.items.map((itemId) =>
            this.itemCollection.find((item) => itemId == item.id)
          );

          this.editedItem = Object.assign({}, data);
          this.loader = false;
          this.modalData.dialog = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
          this.loader = false;
        });
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.categories_num > 0) {
        this.alert.show = true;
      } else {
        this.dialogDelete = true;
      }
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchWebCategory();
          this.fetchWebCategoryTree();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.fetchWebCategory();
      this.fetchWebCategoryTree();
      this.editedItem = initialFormData();
      if (this.$route.name !== "webCategories")
        this.$router.push({ name: "webCategories" });
    },

    closeDelete() {
      this.editedItem = Object.assign({}, initialFormData);
      this.dialogDelete = false;
    },

    handleSaveModalForm(item = null) {
      // this.handleCloseModalForm();
      console.log(this.routePath + item.id);
      if (this.permissionCan("view") && !this.dialogDelete && item.id) {
        this.$router.push(this.routePath + item.id);
      }
    },

    filterCollection(coll) {
      if (!this.showAll) {
        this.collection = coll;
      } else {
        this.collection = coll.filter((item) => {
          return item.status == 1;
        });
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name === "inventory.itemCategory." + action
      );
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("itemCategory") > -1
        );
      }
    },

    fetchWebCategoryTree() {
      ApiService.get("inventory/webCategory/tree?locale=" + this.appLocale)
        .then(({ data }) => {
          let dataLocalized = data.items.map((item) => {
            if (item.translations.length == 0) {
              item.name = "Name not defined";
              return item;
            }

            if (item.translations[this.appLocale]) {
              item.name = item.translations[this.appLocale].name;
            } else {
              item.name =
                item.translations[Object.keys(item.translations)[0]].name;
            }

            if (item.children && item.children.length > 0) {
              item.children = this.translateChildren(item.children);
            }

            return item;
          });

          this.categoryTree = dataLocalized;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    getTranslated(item) {
      if (item.translations[this.appLocale]) {
        return item.translations[this.appLocale].name;
      } else {
        return item.translations[Object.keys(item.translations)[0]].name;
      }
    },

    handleEditCategory(item) {
      if (
        !this.editedItem.id &&
        this.permissionCan("view") &&
        item.length > 0
      ) {
        this.$router.push(`/inventory/webCategories/${item}`);
      }
    },

    translateChildren(childs) {
      let translatedChilds = childs.map((item) => {
        if (item.translations[this.appLocale]) {
          item.name = item.translations[this.appLocale].name;
        } else {
          item.name = item.translations[Object.keys(item.translations)[0]].name;
        }

        if (item.children && item.children.length > 0) {
          item.children = this.translateChildren(item.children);
        }
        return item;
      });
      return translatedChilds;
    },

    moveMenuItem(tree) {
      this.loader = true;
      ApiService.post(
        ENDPOINT.substring(0, ENDPOINT.length - 1) + "/updatePosition",
        tree
      )
        .then(() => {
          this.fetchWebCategoryTree();
          this.loader = false;
        })
        .catch((error) => {
          console.log("Error!: ", error);
          this.loader = false;
        });
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Category" }]);
    this.fetchWebCategoryTree();
    this.fetchWebCategory();
    this.fetchItem();
    this.fetchQuantityUnit();

    this.setPermissions();
  },
};
</script>
